<template>
  <!-- 引入样式 -->
  <div class="sortProduct">
    <NavHeader />

    <div class="content_container">

    <el-button type="info" @click="$router.push({path:'/activity',query:{id:82}})">
      限时抢购
    </el-button>
     <el-button type="info" @click="$router.push({path:'/activity',query:{id:91}})">
      特价活动
    </el-button>

  
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  components: {
    NavHeader
  },
  data() {
    return {
    }
  },
  computed: {},
  mounted() {
    this.currentChange();
  },
  methods: {
   
    

     
  },
  created() {},
 
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.sortProduct {
  background: #fff;
}
::v-deep .el-collapse-item__header {
  display: inline-block;
}
::v-deep .el-collapse {
  display: inline-block;
  float: left;
}
.box {
  background: #f8f8f8;
  padding: 10px;
  margin-bottom: 5px;
}
.Category {
  li {
    font-size: 14px;

    display: inline-block;
    margin-right: 20px;
  }
  li:hover {
    color: red;
    cursor: pointer;
  }
}
.Category_c {
  font-size: 12px;
  padding: 10px;
  color: #888;
  p {
  }
  // .Category_cc{width: 50%; float: left;}
  .tit {
    font-weight: bold;
  }
  dl {
  }
  dd {
    display: inline-block;
    margin: 5px;
  }
}
.dropdown {
  p {
    font-weight: bold;
  }
  padding: 20px;
  dl {
    margin-bottom: 10px;
  }
  dd {
    color: #888;
    display: inline-block;
    margin: 5px 10px;
  }
  dd:hover {
    color: red;
    cursor: pointer;
  }
  ul {
    li {
      display: inline-block;
    }
  }
}
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.red {
  color: red !important;
}
.name {
}
.hover {
  cursor: pointer;
}
.listBrand li {
  display: inline-block;
  margin: 5px;
  margin-top: 0;
  color: #888;
}
.listBrand li:hover {
  color: red;
  cursor: pointer;
}

.filtrate {
  margin: 10px 0;
  padding: 8px 5px 0 5px;
  position: relative;
  z-index: 10;
  background: #faf9f9;
  color: #806f66;
  line-height: 20px;

  p {
    margin-right: 10px;
    text-align: center;
    display: inline-block;
    margin-left: -1px;
    overflow: hidden;
    padding: 0 5px 0 5px;
    cursor: pointer;
    height: 22px;
    line-height: 20px;
    border: 1px solid #ccc;

    i {
      font-weight: 800;
    }
  }
}

.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;

  height: 50px;
  line-height: 50px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}
.sort-list {
  width: 100%;
  background: #fff;
  @include space-between;

  ul {
    background: #f0f0f0;
    width: 300px;

    li {
      padding: 10;
    }
  }
  ol {
    flex: 1;
    margin: 0 -5px;
    .item {
      background: #fff;
      box-sizing: border-box;
      padding: 10px;

      text-align: center;
      width: 226px;
      margin: 5px;
      border: 1px solid #eee;

      float: left;
      position: relative;

      .item-img-wraper {
        overflow: hidden;

        height: 190px;

        box-sizing: border-box;

        .item-img {
          width: 100%;
          height: 100%;
        }
      }
      .shop_name {
        height: 30px;
        text-align: left;
        line-height: 30px;
        @include space-between;

        p {
          color: #999;
          width: 80%;
          @include ellipsis;
        }
      }
      .item-name {
        text-align: left;
        padding: 5px 0;
        font-size: 14px;
        color: #757575;
        line-height: 20px;
        overflow: hidden;
        // height: 43px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-size: 24px !important;

        p:nth-child(1) {
          text-align: left;
          font-size: 14px !important;
          color: red;
        }
        p:nth-child(2) {
          text-align: left;
          text-decoration: line-through;
          color: #888;
        }
      }
      .shop-name {
        text-align: left;
        color: #999;
        @include ellipsis;

        line-height: 25px;
        height: 25px;
        cursor: pointer;
        font-size: 12px;
      }
      .car {
        text-align: left;
        margin-right: 10px;
        color: #999;
        i {
          font-size: 25px;
          color: #999;
        }
      }
    }
  }
  ol:after {
    content: "";
    width: 30%;
  }
}
.banner {
  width: 100%;
  img {
    width: 100%;
    height: 400px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}
</style>
 